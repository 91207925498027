
const setToClipboard = async blob => {
    const data = [new window.ClipboardItem({ [blob.type]: blob })] 
    
    await navigator.clipboard.write(data)
}

const copyTextToClipboard = async text => {
    // if (canWriteToClipboard) {
        const blob = new Blob([text], { type: 'text/plain' })
        await setToClipboard(blob)
    // }
}

export{copyTextToClipboard}